import type { Response } from 'node-fetch';
import fetch, { Headers, Request, Blob, FormData } from '../dom-equivalents/fetch';
import {
    AccessControlList,
    AuthResponse,
    AuthTokens,
    CordraObject,
    ErrorResponse,
    Options,
    ProgressEvent,
    ProgressCallback,
    QueryParams,
    SearchResults,
    SortField,
    VersionInfo,
    ClientConfig
} from './Interfaces';
import { AuthUtil } from './AuthUtil';

// eslint-disable-next-line @typescript-eslint/no-redeclare
type Blob = InstanceType<typeof Blob>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
type FormData = InstanceType<typeof FormData>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const ReadableStream: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const XMLHttpRequest: any;

/**
 * JsonObjectError is constructed with a JSON object, and will JSON.stringify correctly,
 * but it extends Error so will also carry a stack trace.
 */
export class JsonObjectError extends Error {
    /**
     * Constructs an instance of Error which will JSON.stringify correctly.
     * @param obj A JSON object, the properties of which will become the properties of the constructed Error.
     *     It is recommended to include a property "message".
     */
    constructor(obj: object) {
        super();
        JsonObjectError.assign(this, obj);
    }

    /**
     * Assigns the properties of a JSON object to an existing Error,
     * and ensures that the "message" property is enumerable.
     *
     * @param error An Error
     * @param obj A JSON object, the properties of which will become properties of the Error.
     * @return the passed-in Error
     */
    static assign(error: Error, obj: object): Error {
        if (!obj) return error;
        Object.assign(error, obj);
        // Ensure that 'message' is an enumerable property
        Object.defineProperty(error, 'message', { value: error.message, enumerable: true, configurable: true, writable: true });
        return error;
    }
}

export class CordraClient {

    private static AuthUtil = AuthUtil;

    private static readonly DEFAULT_AUTH_TOKEN_EXPIRATION_MS = 600_000; // client-side auth token expiration

    /** The URI of the Cordra instance, including protocol */
    baseUri: string;
    /** Set of default options to use with this client instance */
    defaultOptions: Options;
    /** Client-wide configuration */
    config: ClientConfig;

    private authTokens: AuthTokens;

    static async checkForErrors(response: Response | ErrorResponse): Promise<Response> {
        if ((response as Response).ok) return response as Response;
        return CordraClient.standardizeError(response);
    }

    private static async standardizeError(response: Response | ErrorResponse): Promise<never> {
        const errorInfo = await CordraClient.getErrorMessageFromResponse(response);
        const errorResponse: ErrorResponse = {
            status: response.status,
            statusText: response.statusText,
            message: errorInfo.message,
            body: errorInfo.body
        };
        if (response instanceof Error) {
            // if it was already an Error, keep the original stack trace
            throw JsonObjectError.assign(response, errorResponse);
        }
        throw new JsonObjectError(errorResponse);
    }

    private static async getErrorMessageFromResponse(response: Response | ErrorResponse): Promise<{ message?: string; body?: object }> {
        if ((response as ErrorResponse).message) return response as ErrorResponse;
        const res: { message: string; body?: object } = { message: "Something went wrong." };
        if ((response as Response).text as unknown) {
            const responseText = await (response as Response).text();
            if (!responseText) {
                res.message = "Error: " + response.status + " " + response.statusText;
            } else {
                try {
                    const json = JSON.parse(responseText);
                    res.body = json;
                    if (json.message) {
                        res.message = json.message;
                    } else if (json.error_description) {
                        res.message = json.error_description;
                    } else if (json.error) {
                        res.message = json.error;
                    } else {
                        res.message = responseText;
                    }
                } catch (e) {
                    res.message = responseText;
                }
            }
        } else {
            res.message = "Something went wrong: " + response.status + " " + response.statusText;
        }
        return res;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static returnJsonPromise(response: Response): Promise<any> {
        return response.json();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static returnJsonOrUndefinedPromise(response: Response): Promise<any> {
        const contentType = response.headers.get("Content-Type");
        if (!contentType) {
            return Promise.resolve(undefined);
        } else {
            return response.json();
        }
    }

    private static ensureSlash(baseUri: string): string {
        if (baseUri.slice(-1) !== '/') {
            baseUri = baseUri + '/';
        }
        return baseUri;
    }

    private static getRangeHeader(start: number = -1, end: number = -1): string {
        if (start > -1 && end > -1) {
            return "bytes=" + start + "-" + end;
        } else if (start > -1 && end === -1) {
            return "bytes=" + start + "-";
        } else if (start === -1 && end > -1) {
            return "bytes=-" + end;
        } else {
            return '';
        }
    }

    private static getEncodedWithSlashes(toEncode: string): string {
        return encodeURIComponent(toEncode).replace(/%2F/g, '/');
    }

    /**
     * Creates a Cordra Client, optionally setting default options.
     *
     * @param baseUri The URI of the Cordra instance, including protocol
     * @param options Set of default options to use with this client instance
     * @example
     * ```javascript
     *
     * // No default options
     * const httpsClient = new CordraClient("https://localhost:8443");
     *
     * // Setting default options
     * const options = {
     *   isDryRun: true
     * };
     * const httpClient = new CordraClient("http://localhost:8080/", options);
     * ```
     */
    constructor(baseUri: string, options?: Options, config?: ClientConfig) {
        this.baseUri = CordraClient.ensureSlash(baseUri);
        this.defaultOptions = options || {};
        this.config = config || {};
        this.authTokens = AuthUtil.retrieveAuthTokens(this.baseUri);
    }

    /**
     * Builds a Headers object containing the authentication headers corresponding to the given options.
     *
     * @param options Options to use for this request
     */
    async buildAuthHeaders(options: Options = this.defaultOptions): Promise<Headers> {
        const headersObj = await this.buildAuthHeadersReturnDetails(options);
        if (headersObj.unauthenticated) throw new JsonObjectError({ message: "Unauthenticated" });
        return headersObj.headers;
    }

    private getCachedToken(userKey : string) : string | undefined {
        if (!userKey) return undefined;
        const tokenInfo = this.authTokens[userKey];
        if (!tokenInfo) return undefined;
        const now = Date.now();
        if (tokenInfo.lastUsed && (now - tokenInfo.lastUsed) <= (this.config?.authTokenExpirationMs || CordraClient.DEFAULT_AUTH_TOKEN_EXPIRATION_MS)) {
            tokenInfo.lastUsed = now;
            AuthUtil.storeAuthTokens(this.baseUri, this.authTokens);
            return tokenInfo.token;
        } else {
            delete this.authTokens[userKey];
            AuthUtil.storeAuthTokens(this.baseUri, this.authTokens);
            return undefined;
        }
    }

    async buildAuthHeadersReturnDetails(options: Options = this.defaultOptions, acquireNewToken: boolean = true): Promise<{ isStoredToken?: boolean; unauthenticated?: boolean; headers: Headers }> {
        if (!options) return { headers: new Headers() };
        if (options.token) return { isStoredToken: false, headers: await AuthUtil.buildAuthHeadersFromOptions(options) };
        const userKey = options.userId || options.username;
        if (!userKey) return { headers: await AuthUtil.buildAuthHeadersFromOptions(options) };
        const token = this.getCachedToken(userKey);
        if (token) return { isStoredToken: true, headers: await AuthUtil.buildAuthHeadersFromOptions(options, token) };
        if (!options.password && !options.privateKey) {
            // No stored credentials so cannot authenticate---was expecting a stored token
            return { unauthenticated: true, headers: await AuthUtil.buildAuthHeadersFromOptions(options) };
        }
        if (acquireNewToken) {
            const authResponse = await this.authenticate(options);
            return { isStoredToken: true, headers: await AuthUtil.buildAuthHeadersFromOptions(options, authResponse.access_token) };
        } else {
            return { headers: await AuthUtil.buildAuthHeadersFromOptions(options) };
        }
    }

    async retryAfterTokenFailure<T>(options: Options, fetcher: (headers: Headers) => Promise<T>) : Promise<T> {
        const firstAuthHeadersObj = await this.buildAuthHeadersReturnDetails(options);
        if (firstAuthHeadersObj.unauthenticated) throw new JsonObjectError({ message: "Unauthenticated" });
        if (!firstAuthHeadersObj.isStoredToken) return fetcher(firstAuthHeadersObj.headers);
        try {
            // necessary to await here in order for try/catch to work
            return await fetcher(firstAuthHeadersObj.headers);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
            // we just re-throw e, making it fetcher's responsibility for the form of e
            if (e.status !== 401) throw e;
            const userKey = options.userId || options.username;
            if (!userKey) throw e;
            delete this.authTokens[userKey];
            const secondAuthHeaders = await this.buildAuthHeaders(options);
            // minor bug in @typescript-eslint/return-await: no need to await here unless a finally block added
            // eslint-disable-next-line @typescript-eslint/return-await
            return fetcher(secondAuthHeaders);
        }
    }

    private generateQueryAssignment(queryParameter: string, value: unknown): string | null {
        if (value === null || value === undefined) return null;
        if (queryParameter === "attributes") {
            return "attributes=" + encodeURIComponent(JSON.stringify(value));
        } else if (queryParameter === "deleteCurrentObjects") {
            if (value) return "deleteCurrentObjects";
        } else if (queryParameter === "dryRun") {
            if (value) return "dryRun";
        } else if (queryParameter === "facets") {
            return "facets=" + CordraClient.getEncodedWithSlashes(JSON.stringify(value));
        } else if (queryParameter === "filter") {
            return "filter=" + encodeURIComponent(JSON.stringify(value));
        } else if (queryParameter === "filterQueries") {
            return "filterQueries=" + CordraClient.getEncodedWithSlashes(JSON.stringify(value));
        } else if (queryParameter === "full") {
            if (value) return "full=true";
        } else if (queryParameter === "handle") {
            return "handle=" + CordraClient.getEncodedWithSlashes(value as string);
        } else if (queryParameter === "ids") {
            if (value) return "ids";
        } else if (queryParameter === "includeResponseContext") {
            if (value) return "includeResponseContext";
        } else if (queryParameter === "includeScore") {
            if (value) return "includeScore=true";
        } else if (queryParameter === "includeVersions") {
            if (value) return "includeVersions=true";
        } else if (queryParameter === "jsonPointer") {
            return "jsonPointer=" + CordraClient.getEncodedWithSlashes(value as string);
        } else if (queryParameter === "method") {
            return "method=" + CordraClient.getEncodedWithSlashes(value as string);
        } else if (queryParameter === "objectId") {
            return "objectId=" + CordraClient.getEncodedWithSlashes(value as string);
        } else if (queryParameter === "pageNum") {
            return "pageNum=" + value;
        } else if (queryParameter === "pageSize") {
            return "pageSize=" + value;
        } else if (queryParameter === "payload") {
            return "payload=" + CordraClient.getEncodedWithSlashes(value as string);
        } else if (queryParameter === "query") {
            return "query=" + CordraClient.getEncodedWithSlashes(value as string);
        } else if (queryParameter === "requestContext") {
            return "requestContext=" + encodeURIComponent(JSON.stringify(value as string));
        } else if (queryParameter === "sortFields") {
            const sortFields = value as Array<SortField>;
            return "sortFields=" + sortFields.map(field => {
                let fieldString = CordraClient.getEncodedWithSlashes(field.name);
                if (field.reverse) fieldString += '%20DESC';
                return fieldString;
            }).join(',');
        } else if (queryParameter === "static") {
            if (value) return "static";
        } else if (queryParameter === "suffix") {
            return "suffix=" + CordraClient.getEncodedWithSlashes(value as string);
        } else if (queryParameter === "type") {
            return "type=" + CordraClient.getEncodedWithSlashes(value as string);
        }
        return null;
    }

    private generateQueryString(params: Record<string, unknown>): string {
        const assignments: string[] = [];
        for (const [key, value] of Object.entries(params)) {
            const assignment = this.generateQueryAssignment(key, value);
            if (assignment) assignments.push(assignment);
        }
        return assignments.join('&');
    }

    /**
     * Authenticates using the given options.
     *
     * @param options Options to use for this request
     * @return The authentication response
     *
     * @example
     * ```javascript
     *
     * const authOptions = {
     *   username: 'admin',
     *   password: 'password'
     * };
     * const client = new CordraClient("https://localhost:8443");
     * client.authenticate(authOptions);
     * ```
     */
    async authenticate(options: Options = this.defaultOptions): Promise<AuthResponse> {
        const tokenRequest = await AuthUtil.createTokenRequest(options);
        let uri = this.baseUri + 'auth/token';
        const params = {
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        const authResponse = await fetch(uri, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(tokenRequest)
        })
        .then(CordraClient.checkForErrors)
        .then(CordraClient.returnJsonPromise);
        if (!authResponse.active) {
            throw new JsonObjectError({ message: 'Authorization failed' });
        }
        let userKey = options.userId || options.username;
        if (!userKey) {
            userKey = authResponse.userId || authResponse.username;
        }

        if (userKey) {
            const token = authResponse.access_token;
            this.authTokens[userKey] = { token, lastUsed: Date.now() };
            AuthUtil.storeAuthTokens(this.baseUri, this.authTokens);
        }
        return authResponse;
    }

    /**
     * Gets the authentication status for the supplied options. By default, returns active flag, userId, and username.
     *
     * @param full Whether to get full auth info, including types user can create and groups user is a member of
     * @param options Options to use for this request
     */
    async getAuthenticationStatus(full: boolean = false, options: Options = this.defaultOptions): Promise<AuthResponse> {
        const userKey = options.userId || options.username;
        const headersObj = await this.buildAuthHeadersReturnDetails(options, false);
        let uri = this.baseUri + 'check-credentials';
        const params = {
            full,
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        const resp = await fetch(uri, {
            method: 'GET',
            headers: headersObj.headers
        })
        .then(CordraClient.checkForErrors)
        .then(CordraClient.returnJsonPromise);
        if (headersObj.unauthenticated || !headersObj.isStoredToken || resp.active || !userKey) return resp;
        delete this.authTokens[userKey];
        const secondHeaders = await AuthUtil.buildAuthHeadersFromOptions(options);
        return fetch(uri, {
            method: 'GET',
            headers: secondHeaders
        })
        .then(CordraClient.checkForErrors)
        .then(CordraClient.returnJsonPromise);
    }

    /**
     * Requests a password change for the currently authenticated user.
     *
     * @param newPassword The new password
     * @param options Options to use for this request
     */
    async changePassword(newPassword: string, options: Options): Promise<Response> {
        let uri = this.baseUri + 'users/this/password';
        const params = {
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        const headers = await AuthUtil.buildAuthHeadersFromOptions(options);
        const resp = await fetch(uri, {
            method: 'PUT',
            headers,
            body: newPassword
        })
        .then(CordraClient.checkForErrors);
        const userKey = options.userId || options.username;
        if (userKey) {
            delete this.authTokens[userKey];
            AuthUtil.storeAuthTokens(this.baseUri, this.authTokens);
        }
        return resp;
    }

    /**
     * Requests a password change for the admin user.
     *
     * @param newPassword The new password
     * @param options Options to use for this request
     */
    async changeAdminPassword(newPassword: string, options: Options): Promise<Response> {
        let uri = this.baseUri + 'adminPassword';
        const params = {
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        const headers = await AuthUtil.buildAuthHeadersFromOptions(options);
        const resp = await fetch(uri, {
            method: 'PUT',
            headers,
            body: JSON.stringify({ password: newPassword }, null, ' ')
        })
        .then(CordraClient.checkForErrors);
        // eslint-disable-next-line @typescript-eslint/dot-notation
        delete this.authTokens['admin'];
        AuthUtil.storeAuthTokens(this.baseUri, this.authTokens);
        return resp;
    }

    /**
     * Deletes any stored authentication token locally, and revokes the token at the server.
     *
     * @param options Options to use for this request
     */
    async signOut(options: Options = this.defaultOptions): Promise<AuthResponse> {
        const userKey = options.userId || options.username;
        if (!userKey) {
            return { active: false };
        }
        const token = this.getCachedToken(userKey);
        if (!token) {
            return { active: false };
        }
        const tokenRequest = await AuthUtil.createTokenRequest(options, token);
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        let uri = this.baseUri + 'auth/revoke';
        const params = {
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        const resp = fetch(uri, {
            method: 'POST',
            headers,
            body: JSON.stringify(tokenRequest)
        })
        .then(CordraClient.checkForErrors)
        .then(CordraClient.returnJsonPromise);
        if (userKey) {
            delete this.authTokens[userKey];
            AuthUtil.storeAuthTokens(this.baseUri, this.authTokens);
        }
        return resp;
    }

    private async genericSearch<T>(query: string, options: Options, params?: QueryParams): Promise<SearchResults<T>> {
        let uri = this.baseUri + 'objects';
        const queryParametersAndValues = {};
        if (params) {
            Object.assign(queryParametersAndValues, {
                query,
                attributes: options.attributes,
                requestContext: options.requestContext,
                facets: params.facets,
                filter: params.filter,
                filterQueries: params.filterQueries,
                ids: params.ids,
                includeScore: params.includeScore,
                includeVersions: params.includeVersions,
                pageNum: params.pageNum ?? 0,
                pageSize: params.pageSize ?? -1,
                sortFields: params.sortFields
            });
        } else {
            Object.assign(queryParametersAndValues, {
                query,
                attributes: options.attributes,
                requestContext: options.requestContext
            });
        }
        const queryString = this.generateQueryString(queryParametersAndValues);
        if (queryString) uri += "?" + queryString;
        return this.retryAfterTokenFailure<SearchResults<T>>(options, async headers => {
            return fetch(uri, {
                method: 'GET',
                headers
            })
            .then(CordraClient.checkForErrors)
            .then(CordraClient.returnJsonPromise);
        });
    }

    /**
     * Searches Cordra for objects matching a given query. The query format is that used by the indexing
     * backend, which is generally the inter-compatible Lucene/Solr/Elasticsearch format for fielded search.
     *
     * @param query The query string to search
     * @param params Parameters for this query
     * @param options Options to use for this request
     * @example
     * ```javascript
     *
     * // Search for all objects in Cordra
     * client.search("*:*");
     *
     * // Search for all schemas.
     * // Sort results in descending order by name and return 2 results
     * const params = {
     *   pageSize: 2,
     *   sortFields: [{name: "/name", reverse: true}]
     * };
     * client.search("type:Schema", params);
     *
     * // Search for everything that is not a schema.
     * // Sort results by type and then id
     * const params = {
     *   sortFields: [{name: "type"}, {name: "id"}]
     * };
     * client.search("*:* -type:Schema", params);
     *
     * // Search for everything and returns facets
     * // for each type
     * const params = {
     *   facets: [{field: "type"}]
     * };
     * client.search("*:*", params);
     *
     * // Search for everything, filter using filter query
     * const params = {
     *   filterQueries: ["+type:Schema"]
     * };
     * client.search("*:*", params);
     * ```
     */
    async search(query: string, params?: QueryParams, options: Options = this.defaultOptions): Promise<SearchResults<CordraObject>> {
        // Postpone until v3 to continue working with old Cordra; at that time can use simple JSON POST
        // const uri = this.baseUri + 'search';<
        // const data = { query, ...params };
        if (!params) {
            params = { ids: false };
        } else {
            params.ids = false;
        }
        return this.genericSearch<CordraObject>(query, options, params);
    }

    /**
     * Searches Cordra for objects matching a given query. Returns the object IDs instead of full objects. The
     * query format is that used by the indexing backend, which is generally the inter-compatible
     * Lucene/Solr/Elasticsearch format for fielded search.
     *
     * @param query The query string to search
     * @param params Parameters for this query
     * @param options Options to use for this request
     */
    async searchHandles(query: string, params?: QueryParams, options: Options = this.defaultOptions): Promise<SearchResults<string>> {
        // Postpone until v3 to continue working with old Cordra; at that time can use simple JSON POST
        // const uri = this.baseUri + 'search';
        // const data = { query, ...params, ids: true };
        if (!params) {
            params = { ids: true };
        } else {
            params.ids = true;
        }
        return this.genericSearch<string>(query, options, params);
    }

    /**
     * Retrieves a list of all objects in Cordra.
     *
     * @param options Options to use for this request
     */
    list(options: Options = this.defaultOptions): Promise<SearchResults<CordraObject>> {
        return this.search('*:*', undefined, options);
    }

    /**
     * Retrieves a list of the handles for all objects in Cordra.
     *
     * @param options Options to use for this request
     */
    listHandles(options: Options = this.defaultOptions): Promise<SearchResults<string>> {
        return this.searchHandles('*:*', undefined, options);
    }

    async genericGet<T>(id: string, options: Options, processRequest: (request: Request) => Promise<T>): Promise<T> {
        let uri = this.baseUri + 'objects/' + CordraClient.getEncodedWithSlashes(id);
        const params = {
            filter: options.filter,
            full: true,
            includeResponseContext: options.includeResponseContext,
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        return this.retryAfterTokenFailure<T>(options, async headers => {
            const request = new Request(uri, {
                method: 'GET',
                headers
            });
            return processRequest(request);
        });
    }

    /**
     * Retrieves an object from Cordra by ID, throwing if not found.
     *
     * @param id The ID of the object to retrieve
     * @param options Options to use for this request
     * @return the object; throws if not found
     */
    async get(id: string, options: Options = this.defaultOptions): Promise<CordraObject> {
        return this.genericGet<CordraObject>(id, options,
            (request: Request): Promise<CordraObject> => {
                return fetch(request).then(CordraClient.checkForErrors).then(CordraClient.returnJsonPromise);
            }
        );
    }

   /**
     * Retrieves an object from Cordra by ID, returning null if not found.
     *
     * @param id The ID of the object to retrieve
     * @param options Options to use for this request
     * @return the object; null if not found
     */
    async getOrNull(id: string, options: Options = this.defaultOptions): Promise<CordraObject | null> {
        return this.genericGet<CordraObject | null>(id, options,
            async (request: Request): Promise<CordraObject | null> => {
                const response = await fetch(request);
                if (response.status === 404) return null;
                await CordraClient.checkForErrors(response);
                return response.json() as Promise<CordraObject>;
            }
        );
    }

    /**
     * Creates a new object.
     *
     * @param cordraObject An object containing the type and content of the new object.
     * @param progressCallback Callback for progress notification
     * @param options Options to use for this request
     * @example
     * ```javascript
     *
     * const cordraObject = {
     *     type: "Document",
     *     content: { id: '', name: 'test doc' }
     * }
     * client.create(cordraObject);
     * ```
     */
    async create(cordraObject: CordraObject, progressCallback?: ProgressCallback, options: Options = this.defaultOptions): Promise<CordraObject> {
        return this.createOrUpdate(cordraObject, true, progressCallback, options);
    }

    /**
     * Updates an object.
     *
     * @param cordraObject An object containing the id of the object and the new content.
     * @param progressCallback Callback for progress notification
     * @param options Options to use for this request
     * @example
     * ```javascript
     *
     * const cordraObject = {
     *     id: "test/12345",
     *     content: { id: "test/12345", name: 'a different name' }
     * }
     * client.update(cordraObject);
     * ```
     */
    async update(cordraObject: CordraObject, progressCallback?: ProgressCallback, options: Options = this.defaultOptions): Promise<CordraObject> {
        return this.createOrUpdate(cordraObject, false, progressCallback, options);
    }

    /**
     * Deletes an object.
     *
     * @param id ID of the object to delete
     * @param options Options to use for this request
     */
    async delete(id: string, options: Options = this.defaultOptions): Promise<Response> {
        let uri = this.baseUri + "objects/" + CordraClient.getEncodedWithSlashes(id);
        const params = {
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        return this.retryAfterTokenFailure(options, headers => {
            return fetch(uri, {
                method: 'DELETE',
                headers
            })
            .then(CordraClient.checkForErrors);
        });
    }

    /**
     * Retrieves the value of a property from the content of an object.
     *
     * @param id ID of the object
     * @param jsonPointer JSON Pointer of the property to retrieve, in the content of the object
     * @param options Options to use for this request
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async getObjectProperty(id: string, jsonPointer: string, options: Options = this.defaultOptions): Promise<any> {
        let uri = this.baseUri + 'objects/' + CordraClient.getEncodedWithSlashes(id);
        const params = {
            full: options.full,
            jsonPointer,
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        return this.retryAfterTokenFailure(options, headers => {
            return fetch(uri, {
                method: 'GET',
                headers
            })
            .then(CordraClient.checkForErrors)
            .then(CordraClient.returnJsonPromise);
        });
    }

    /**
     * Retrieves the value of a property from a full Cordra object (not restricted to content).
     *
     * @param id ID of the object
     * @param jsonPointer JSON Pointer of the property to retrieve, in the full Cordra object
     * @param options Options to use for this request
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async getObjectPropertyFromFullCordraObject(id: string, jsonPointer: string, options: Options = this.defaultOptions): Promise<any> {
        if (!options) options = { full: true };
        else options.full = true;
        return this.getObjectProperty(id, jsonPointer, options);
    }

    /**
     * Updates only the given property in the content of an object.
     *
     * @param id ID of the object
     * @param jsonPointer JSON Pointer of the property to retrieve, in the content of the object
     * @param value Data to insert into content at that JSON Pointer
     * @param options Options to use for this request
     */
    async updateObjectProperty(id: string, jsonPointer: string, value: unknown, options: Options = this.defaultOptions): Promise<CordraObject> {
        let uri = this.baseUri + 'objects/' + CordraClient.getEncodedWithSlashes(id);
        const params = {
            jsonPointer,
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        return this.retryAfterTokenFailure(options, headers => {
            return fetch(uri, {
                method: 'PUT',
                headers,
                body: JSON.stringify(value, null, ' ')
            })
            .then(CordraClient.checkForErrors)
            .then(CordraClient.returnJsonPromise);
        });
    }

    /**
     * Generates a URI that can be used to download the payload of an object.
     *
     * @param id ID of the object
     * @param payloadName Name of the desired payload
     * @param options Options to use for this request
     */
    getPayloadDownloadLink(id: string, payloadName: string, options: Options = this.defaultOptions): string {
        let uri = this.baseUri + 'objects/' + CordraClient.getEncodedWithSlashes(id);
        const params = {
            payload: payloadName,
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        return uri;
    }

    private async templateGetPayload(id: string, payloadName: string, start: number | undefined, end: number | undefined, options: Options): Promise<Blob> {
        const uri = this.getPayloadDownloadLink(id, payloadName, options);
        return this.retryAfterTokenFailure(options, headers => {
            const maybeRangeHeader = CordraClient.getRangeHeader(start, end);
            if (maybeRangeHeader) headers.append("Range", maybeRangeHeader);
            return fetch(uri, {
                method: 'GET',
                headers
            })
            .then(CordraClient.checkForErrors)
            .then(resp => resp.blob());
        });
    }

    /**
     * Gets a payload for an object.
     *
     * @param id ID of the object
     * @param payloadName Name of the desired payload
     * @param options Options to use for this request
     */
    async getPayload(id: string, payloadName: string, options: Options = this.defaultOptions): Promise<Blob> {
        return this.templateGetPayload(id, payloadName, undefined, undefined, options);
    }

    /**
     * Gets part of the payload of an object. This can be useful streaming payloads.
     *
     * @param id ID of the object
     * @param payloadName Name of the desired payload
     * @param start Beginning of payload range
     * @param end End of payload range
     * @param options Options to use for this request
     */
    async getPartialPayload(id: string, payloadName: string, start: number, end: number, options: Options = this.defaultOptions): Promise<Blob> {
        return this.templateGetPayload(id, payloadName, start, end, options);
    }

    /**
     * Removes a payload from an object.
     *
     * @param object The object to be updated
     * @param payloadName Name of the desired payload
     * @param options Options to use for this request
     */
    async deletePayload(object: CordraObject, payloadName: string, options: Options = this.defaultOptions): Promise<CordraObject> {
        if (object.payloads === undefined) return object;
        if (object.payloadsToDelete === undefined) object.payloadsToDelete = [];
        object.payloadsToDelete.push(payloadName);
        object.payloads = undefined; // We're just deleting payloads, so don't send current list to update.
        return this.update(object, undefined, options);
    }

    private async getOrUpdateAclForObject(id: string, options: Options, method: "GET" | "PUT", newAcl?: object): Promise<AccessControlList> {
        let uri = this.baseUri + 'acls/' + CordraClient.getEncodedWithSlashes(id);
        const params = {
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        const body = (method === "GET") ? null : JSON.stringify(newAcl);
        return this.retryAfterTokenFailure(options, headers => {
            return fetch(uri, {
                method,
                headers,
                body
            })
            .then(CordraClient.checkForErrors)
            .then(CordraClient.returnJsonPromise);
        });
    }

    /**
     * Gets the access control list for an object.
     *
     * @param id ID of the object
     * @param options Options to use for this request
     */
    async getAclForObject(id: string, options: Options = this.defaultOptions): Promise<AccessControlList> {
        return this.getOrUpdateAclForObject(id, options, "GET");
    }

    /**
     * Updates the access control list for an object.
     *
     * @param id ID of the object
     * @param newAcl New ACL to set on object
     * @param options Options to use for this request
     */
    async updateAclForObject(id: string, newAcl: object, options: Options = this.defaultOptions): Promise<AccessControlList> {
        return this.getOrUpdateAclForObject(id, options, "PUT", newAcl);
    }

    private async publishVersionOrGetVersionsFor<T>(id: string, options: Options, method: "POST" | "GET"): Promise<T> {
        let uri = this.baseUri + "versions";
        const params = {
            objectId: id,
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        return this.retryAfterTokenFailure<T>(options, headers => {
            return fetch(uri, {
                method,
                headers
            })
            .then(CordraClient.checkForErrors)
            .then(CordraClient.returnJsonPromise);
        });
    }

    /**
     * Creates a new version snapshot of an object.
     *
     * @param id ID of the object
     * @param options Options to use for this request
     */
    async publishVersion(id: string, options: Options = this.defaultOptions): Promise<VersionInfo> {
        return this.publishVersionOrGetVersionsFor<VersionInfo>(id, options, "POST");
    }

    /**
     * Retrieves all version snapshots for an object.
     *
     * @param id ID of the object
     * @param options Options to use for this request
     */
    async getVersionsFor(id: string, options: Options = this.defaultOptions): Promise<Array<VersionInfo>> {
        return this.publishVersionOrGetVersionsFor<Array<VersionInfo>>(id, options, "GET");
    }

    /**
     * Starts a background process to update all handles in the system. Used to propagate prefix changes.
     *
     * @param options Options to use for this request
     */
    async updateAllHandles(options: Options = this.defaultOptions): Promise<Response> {
        let uri = this.baseUri + 'updateHandles';
        const params = {
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        return this.retryAfterTokenFailure<Response>(options, headers => {
            return fetch(uri, {
                method: "POST",
                headers
            })
            .then(CordraClient.checkForErrors);
        });
    }

    /**
     * Gets status of handle update process.
     *
     * @param options Options to use for this request
     */
    async getHandleUpdateStatus(options: Options = this.defaultOptions): Promise<object> {
        let uri = this.baseUri + 'updateHandles';
        const params = {
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        return this.retryAfterTokenFailure<object>(options, headers => {
            return fetch(uri, {
                method: "GET",
                headers
            })
            .then(CordraClient.checkForErrors)
            .then(CordraClient.returnJsonPromise);
        });
    }

    /**
     * Batch uploads objects, optionally deleting existing objects.
     *
     * @param objects Array of objects to upload
     * @param deleteCurrent Whether to delete current objects before uploading
     * @param options Options to use for this request
     */
    async uploadObjects(objects: Array<CordraObject>, deleteCurrent: boolean = false, options: Options = this.defaultOptions): Promise<object> {
        let uri = this.baseUri + "uploadObjects";
        const params = {
            deleteCurrentObjects: deleteCurrent,
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        return this.retryAfterTokenFailure(options, headers => {
            return fetch(uri, {
                method: "PUT",
                headers,
                body: JSON.stringify(objects)
            })
            .then(CordraClient.checkForErrors)
            .then(CordraClient.returnJsonPromise);
        });
    }

    /**
     * Lists the methods available for a given object.
     *
     * @param id The id of the object you want to list methods of. Either objectId or type is required.
     * @param options Options to use for this request
     */
    async listMethods(id: string, options: Options = this.defaultOptions): Promise<Array<string>> {
        let uri = this.baseUri + "listMethods";
        const params = {
            objectId: id,
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        return this.retryAfterTokenFailure(options, headers => {
            return fetch(uri, {
                method: "GET",
                headers
            })
            .then(CordraClient.checkForErrors)
            .then(CordraClient.returnJsonPromise);
        });
    }

    /**
     * Lists the methods available for a given type.
     *
     * @param type A Cordra type; depending on the static parameter, this will list static methods on that type, or instance methods on objects of the type.
     * @param listStatic If true, listing methods for a type will list static methods instead of instance methods.
     * @param options Options to use for this request
     */
    async listMethodsForType(type: string, listStatic: boolean = false, options: Options = this.defaultOptions): Promise<Array<string>> {
        let uri = this.baseUri + 'listMethods';
        const params = {
            attributes: options.attributes,
            requestContext: options.requestContext,
            static: listStatic,
            type
        };
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        return this.retryAfterTokenFailure(options, headers => {
            return fetch(uri, {
                method: 'GET',
                headers
            })
            .then(CordraClient.checkForErrors)
            .then(CordraClient.returnJsonPromise);
        });
    }

    /**
     * Calls a method on an object instance, expecting a JSON response.
     *
     * @param objectId The id of the object on which to call an instance method. Either objectId or type is required.
     * @param method The name of the method to call.
     * @param params The parameters to pass to the method as either a Body type or JSON-representable data.
     * @param options Options to use for this request
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async callMethod(objectId: string, method: string, params: unknown, options: Options = this.defaultOptions): Promise<any> {
        return this.callMethodAsResponse(objectId, method, params, options)
        .then(CordraClient.returnJsonOrUndefinedPromise);
    }

    /**
     * Calls a method on an object instance, returning a Response (from which binary data can be retrieved).
     *
     * @param objectId The id of the object on which to call an instance method. Either objectId or type is required.
     * @param method The name of the method to call.
     * @param params The parameters to pass to the method as either a Body type or JSON-representable data.
     * @param options Options to use for this request
     */
    async callMethodAsResponse(objectId: string, method: string, params: unknown, options: Options = this.defaultOptions): Promise<Response> {
        let uri = this.baseUri + 'call';
        const queryParametersAndValues = {
            attributes: options.attributes,
            method,
            objectId,
            requestContext: options.requestContext
        };
        const queryString = this.generateQueryString(queryParametersAndValues);
        if (queryString) uri += "?" + queryString;
        return this.retryAfterTokenFailure(options, async headers => {
            await CordraClient.addCallHeaders(headers, params, options);
            return fetch(uri, {
                method: 'POST',
                headers,
                body: await CordraClient.wrapCallParams(params)
            })
            .then(CordraClient.checkForErrors);
        });
    }

    /**
     * Calls a method for a given type, expecting a JSON response.
     *
     * @param method The name of the method to call.
     * @param params The parameters to pass to the method as either a Body type or JSON-representable data.
     * @param type The id of the object on which to call an instance method. Either objectId or type is required.
     * @param options Options to use for this request
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async callMethodForType(type: string, method: string, params: unknown, options: Options = this.defaultOptions): Promise<any> {
        return this.callMethodForTypeAsResponse(type, method, params, options)
        .then(CordraClient.returnJsonOrUndefinedPromise);
    }

    /**
     * Calls a method for a given type, returning a Response (from which binary data can be retrieved).
     *
     * @param method The name of the method to call.
     * @param params The parameters to pass to the method as either a Body type or JSON-representable data.
     * @param type The id of the object on which to call an instance method. Either objectId or type is required.
     * @param options Options to use for this request
     */
    async callMethodForTypeAsResponse(type: string, method: string, params: unknown, options: Options = this.defaultOptions): Promise<Response> {
        let uri = this.baseUri + 'call';
        const queryParametersAndValues = {
            attributes: options.attributes,
            method,
            requestContext: options.requestContext,
            type
        };
        const queryString = this.generateQueryString(queryParametersAndValues);
        if (queryString) uri += "?" + queryString;
        return this.retryAfterTokenFailure(options, async headers => {
            await CordraClient.addCallHeaders(headers, params, options);
            return fetch(uri, {
                method: 'POST',
                headers,
                body: await CordraClient.wrapCallParams(params)
            })
            .then(CordraClient.checkForErrors);
        });
    }

    private static async addCallHeaders(headers: Headers, params: unknown, options?: Options) {
        if (!options) return;
        let mediaType;
        let filename;
        if (options.callHeaders) {
            mediaType = options.callHeaders.mediaType;
            filename = options.callHeaders.filename;
        }
        if (!mediaType) {
            mediaType = await CordraClient.getDefaultMediaType(params);
        }
        if (mediaType) {
            headers.append('Content-Type', mediaType);
        }
        if (filename) {
            headers.append('Content-Disposition', CordraClient.contentDispositionHeaderFor('attachment', filename));
        }
    }

    private static contentDispositionHeaderFor(disposition: string, filename?: string): string {
        if (!filename) return disposition;
        // eslint-disable-next-line no-control-regex
        const asciiVersion = filename.replace(/[^\x00-\x7F]/g, '?');
        const asciiVersionEscaped = filename.replace('\n', '?').replace('\\', '\\\\').replace('"', '\\"');
        if (filename === asciiVersion) {
            return disposition + ';filename="' + asciiVersionEscaped + '"';
        } else {
            const filenameEncoded = encodeURIComponent(filename)
            .replace(/['()]/g, escape) // %27 %28 %29
            .replace(/\*/g, '%2A');
            return disposition + ';filename="' + asciiVersionEscaped + '";filename*=UTF-8\'\'' + filenameEncoded;
        }
    }

    private static async getDefaultMediaType(params: unknown): Promise<string | undefined> {
        if (params === undefined) return undefined;
        // workaround problem with null instanceof Blob in fetch-blob
        if (params === null) return 'application/json';
        if (params instanceof Blob) return undefined;
        if (params instanceof ArrayBuffer) return undefined;
        if (ArrayBuffer.isView(params)) return undefined;
        if (typeof ReadableStream !== 'undefined' && params instanceof ReadableStream) return undefined;
        return 'application/json';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static async wrapCallParams(params: unknown): Promise<any> {
        if (params === undefined) return params;
        // workaround problem with null instanceof Blob in fetch-blob
        if (params === null) return JSON.stringify(params);
        if (params instanceof Blob) return params;
        if (params instanceof ArrayBuffer) return params;
        if (ArrayBuffer.isView(params)) return params;
        if (typeof ReadableStream !== 'undefined' && params instanceof ReadableStream) return params;
        return JSON.stringify(params);
    }

    private async createOrUpdate(cordraObject: CordraObject, isCreate: boolean, progressCallback?: ProgressCallback, options: Options = this.defaultOptions): Promise<CordraObject> {
        // This uses XMLHttpRequest instead of Fetch so that we can attach a progress callback.
        // Someday(tm), Fetch will support this: https://github.com/whatwg/fetch/issues/607
        let uri = this.baseUri + 'objects';
        const params = {
            dryRun: options.isDryRun,
            full: true,
            handle: cordraObject.id,
            includeResponseContext: options.includeResponseContext,
            attributes: options.attributes,
            requestContext: options.requestContext
        };
        if (isCreate) {
            if (!cordraObject.type) { throw new JsonObjectError({ message: 'Create error: "type" must be set in Cordra Object.' }); }
            Object.assign(params, { suffix: options.suffix });
        } else {
            if (!cordraObject.id) { throw new JsonObjectError({ message: 'Create error: "id" must be set in Cordra Object.' }); }
            uri += "/" + CordraClient.getEncodedWithSlashes(cordraObject.id);
        }
        Object.assign(params, { type: cordraObject.type });
        const queryString = this.generateQueryString(params);
        if (queryString) uri += "?" + queryString;
        const body = await this.buildCreateOrUpdateData(cordraObject);
        const method = isCreate ? 'POST' : 'PUT';
        return this.retryAfterTokenFailure(options, headers => {
            if (typeof XMLHttpRequest !== 'undefined') {
                return this.createOrUpdateInnerUsingXMLHttpRequest(method, uri, progressCallback, headers, body);
            } else {
                return this.createOrUpdateInnerUsingFetch(method, uri, progressCallback, headers, body);
            }
        });
    }

    private async createOrUpdateInnerUsingFetch(method: string, uri: string, progressCallback: ProgressCallback | undefined, headers: Headers, body: FormData): Promise<CordraObject> {
        return fetch(uri, {
            method,
            headers,
            body
        })
        .then(CordraClient.checkForErrors)
        .then(CordraClient.returnJsonPromise);
    }

    private createOrUpdateInnerUsingXMLHttpRequest(method: string, uri: string, progressCallback: ProgressCallback | undefined, headers: Headers, body: FormData): Promise<CordraObject> {
        return new Promise<CordraObject>((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open(method, uri);
            if (progressCallback) {
                xhr.upload.onprogress = (e: ProgressEvent) => {
                    if (e.lengthComputable) {
                        progressCallback(e);
                    }
                };
            }
            headers.forEach((value: string, name: string) => {
                xhr.setRequestHeader(name, value);
            });
            xhr.onload = () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    resolve(JSON.parse(xhr.response));
                } else {
                    const responseBody = xhr.response;
                    const init = {
                        status: xhr.status,
                        statusText: xhr.statusText,
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        headers: this.getHeadersFromXhr(xhr.getAllResponseHeaders())
                    };
                    // This code path only occurs in browsers
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const fetchResponse = new (globalThis as any).Response(responseBody, init);
                    reject(fetchResponse);
                }
            };
            xhr.onerror = () => reject(xhr);
            xhr.send(body);
        }).catch(CordraClient.standardizeError);
    }

    private async buildCreateOrUpdateData(cordraObject: CordraObject): Promise<FormData> {
        const formData = new FormData();
        formData.append('content', JSON.stringify(cordraObject.content));
        if (cordraObject.acl) {
            formData.append('acl', JSON.stringify(cordraObject.acl));
        }
        if (cordraObject.userMetadata) {
            formData.append('userMetadata', JSON.stringify(cordraObject.userMetadata));
        }
        if (cordraObject.payloadsToDelete && cordraObject.payloadsToDelete.length > 0) {
            cordraObject.payloadsToDelete.forEach(payloadToDelete => {
                formData.append('payloadToDelete', payloadToDelete);
            });
        }
        if (cordraObject.payloads && cordraObject.payloads.length > 0) {
            cordraObject.payloads.forEach(payload => {
                if (!payload.body) return;
                formData.append(payload.name, payload.body, payload.filename || '');
            });
        }
        return formData;
    }

    private getHeadersFromXhr(xhrHeaders: string): Headers {
        const headers = new Headers();
        const parsedXhrHeaders = xhrHeaders.trim().split(/[\r\n]+/);
        parsedXhrHeaders.forEach(line => {
            const parts = line.split(': ');
            const name = parts.shift();
            const value = parts.join(': ');
            headers.append(name!, value);
        });
        return headers;
    }
}
