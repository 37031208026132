import type { Blob } from 'node-fetch';
// eslint-disable-next-line @typescript-eslint/no-redeclare
type Blob = InstanceType<typeof Blob>;

// re-do enum to be compatible with https://github.com/tc39/proposal-type-annotations
export const BlobMethod = {
    arrayBuffer: 'arrayBuffer',
    dataUrl: 'dataUrl',
    binaryString: 'binaryString',
    text: 'text',
    stream: 'stream'
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BlobMethod = typeof BlobMethod[keyof typeof BlobMethod];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const FileReader: any;

export class BlobUtil {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static readBlob(blob: Blob, method: BlobMethod = BlobMethod.arrayBuffer, encoding: string = 'utf8'): Promise<any> {
        if (method === BlobMethod.arrayBuffer && blob.arrayBuffer) {
            return blob.arrayBuffer();
        } else if (method === BlobMethod.text && blob.text) {
            return blob.text();
        } else if (method === BlobMethod.stream && blob.stream) {
            return Promise.resolve(blob.stream());
        } else if (method !== BlobMethod.stream && typeof FileReader !== 'undefined') {
            const reader = new FileReader();

            const promise = new Promise(((resolve, reject) => {
                reader.addEventListener('loadend', function onend() {
                    reader.removeEventListener('loadend', onend);
                    if (reader.error) {
                        reject(reader.error);
                    } else {
                        resolve(reader.result);
                    }
                });
            }));

            if (method === BlobMethod.arrayBuffer) {
                reader.readAsArrayBuffer(blob);
            } else if (method === BlobMethod.dataUrl) {
                reader.readAsDataURL(blob);
            } else if (method === BlobMethod.binaryString) {
                reader.readAsBinaryString(blob);
            } else if (method === BlobMethod.text) {
                reader.readAsText(blob, encoding);
            } else {
                return Promise.reject(new Error('Unsupported'));
            }

            return promise;
        } else {
            return Promise.reject(new Error('Unsupported'));
        }
    }
}
